<template>
  <form class="form">

    <FveBlockSwitchActive
      v-if="editForm"
      v-model="form.is_active"
      class="fix-swich"
    />

    <fieldset class="form__fieldset">
      <div class="row">
        <div class="col-12">
          <FveImageMultiplePreview
            name="name"
            v-model="form.courtmedia_set"

            @removeImage="$emit('removeImage', $event)"
          />
          <FveSelectAjaxV2Club
            v-if="shownClub"
            label="Клуб"
            name="club"
            required
            v-model="form.club"
          />
          <FveText
            label="Название"
            name="name"
            required
            v-model="form.name"
          />
        </div>
        <div class="col-12"><div class="row"><div class="col-4">
          <FveSelectValue
            label="Тип помещения"
            name="street"
            :optionGetKey="(option) => option.key"
            :optionGetName="(option) => option.value"
            v-model="form.street"
            :optionList="[{key: 'STREET', value: 'Улица'}, {key: 'ROOM', value: 'Помещение'}]"
          />
        </div></div></div>
        <div class="col-3" v-if="form.street === 'ROOM'">
          <FveText
            label="Высота потолка"
            name="ceiling"
            required
            v-model="form.ceiling"
          />
        </div>
        <div class="col-3">
          <FveText
            label="Размер помещения"
            name="space"
            required
            v-model="form.space"
          />
        </div>
        <div class="col-3">
          <FveText
            label="Вместимость"
            name="capacity"
            required
            v-model="form.capacity"
          />
        </div>
        <div class="col-3">
          <FveSelectValue
            label="Пол"
            name="floor_type"
            :optionGetKey="(option) => option.key"
            :optionGetName="(option) => option.value"
            v-model="form.floor_type"
            :optionList="floorTypeList"
          />
        </div>
        <div class="col-12" v-if="tagList.length > 0">
          <FveMultiSelect
            label="Вид тренировок"
            name="tags"
            v-model="form.tags"
            multiple
            :options="tagList"
            optionFieldId="tag"
            optionFieldName="tag"
          />
        </div>
        <!--
        <div class="col-4">
          <FveSelectLine
            :option-list="ageList"
            :option-get-key="(obj)=>{ return obj.key.toString(); }"
            :option-get-name="(obj)=>{ return obj.value.toString(); }"
            label="Формат тренировок"
            name="age"
            required
            v-model="form.age"
          />
        </div>
        <div class="col-4">
          <FveSelectLine
            :option-list="trainerTypeList"
            :option-get-key="(obj)=>{ return obj.key.toString(); }"
            :option-get-name="(obj)=>{ return obj.value.toString(); }"
            label="Тип тренировок"
            name="type"
            required
            v-model="form.type"
          />
        </div>
        -->
        <div class="col-12">
          <FveMultiSelectInventory
            label="Оборудование"
            name="inventory"
            required2
            v-model="form.inventory"
            :options="inventoryList"
            optionFieldId="name"
            optionFieldName="name"
            @addTag="addInventoryList"
          />
        </div>

      </div>
    </fieldset>
  </form>
</template>

<script>
import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveSelectValue from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectValue";
import FveMultiSelect from "@widgetFormValidate/src/Element/MultiSelect/FveMultiSelect";
// import FveSelectLine from "@widgetFormValidate/src/Element/Select/FveSelectLine";
import FveImageMultiplePreview from '@widgetFormValidate/src/Element/File/FveImageMultiplePreview';
import FveSelectAjaxV2Club from '@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Club';

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveMultiSelectInventory from "@widgetFormValidate/src/Element/MultiSelect/FveMultiSelectInventory";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";

export default {
  mixins: [
    FveFormMixin
  ],
  props: {
    shownClub: Boolean,
    editForm: {type: Boolean, default: false}
  },
  components: {
    FveBlockSwitchActive,
    FveMultiSelectInventory,
    FveText,
    FveSelectValue,
    // FveSelectLine,
    FveMultiSelect,
    FveImageMultiplePreview,
    FveSelectAjaxV2Club
  },
  methods: {
    formSchema() {
      return {
        //
        is_active    : { type: Boolean, default: () => { return true; } },
        //
        name       : { type: String, default: () => { return ''; } },
        ceiling    : { type: [String, Number], default: () => { return ''; } },
        space      : { type: [String, Number], default: () => { return ''; } },
        capacity   : { type: [String, Number], default: () => { return ''; } },
        floor_type : { type: String, default: () => { return ''; } },
        type       : { type: String, default: () => { return 'GRP'; } },
        age        : { type: String, default: () => { return 'ADLT'; } },
        tags       : { type: Array,  default: () => { return []; } },
        inventory  : { type: Array,  default: () => { return []; } },
        courtmedia_set : { type: Array,  default: () => { return []; } },
        club       : { type: Object, default: () => { return null; } },
        //
        street     : { type: String, default: () => { return ''; } },
      };
    },
    addInventoryList(item) {
      this.inventoryList.push(item);
      this.form.inventory.push(item);
    }
  },
  data() {
    return {
      floorTypeList: [],
      tagList: [],
      inventoryList: [],
      ageList: [],
      trainerTypeList: [],
    };
  },
  mounted() {
    RequestManager.Reference.getFloorTypeList({}).then((result) => {
      this.floorTypeList = result;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      this.trainerTypeList = list;
    });
    RequestManager.Reference.getClubInventoryList({}).then((response) => {
      this.inventoryList = response.results;
    });
    RequestManager.Reference.getTagList({}).then((tagObject) => {
      this.tagList = tagObject.results;
    });
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      this.ageList = list;
    });
  }
};

</script>

<style lang="scss" scoped>

</style>
